<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
// import dayjs from 'dayjs';

export default {
  name: 'Login',
  props: ['visible'],
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      countryCode: process.env.VUE_APP_COUNTRY_CODE,
      client: null,
      loginForm: {
        contactNo: '',
        password: '',
        merchantId: '',
      },
      backFullPath: '',
      isLoading: false,
      error: null,
      show: false,
    };
  },
  computed: {
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    ...mapGetters('client', {
      name: 'getName',
    }),
  },
  methods: {
    ...mapActions('config', ['getAdminOption']),
    ...mapActions('auth', ['authenticate', 'resetPassword']),
    ...mapMutations('auth', ['SET_REDIRECT']),
    async login() {
      this.isLoading = true;

      if (!(await this.$refs.form.validate())) {
        this.isLoading = false;
        return;
      }

      try {
        this.$gtag.event('login', {
          'event_category': 'engagement',
          'event_label': 'Login',
          'event_value': 'web',
        });

        await this.authenticate({
          email: this.loginForm.contactNo,
          password: this.loginForm.password,
          method: 'web',
          merchantId: this.profile.merchant_id,
        });

        this.redirect();
      } catch (e) {
        this.error = 'Invalid contact number or password';

        setTimeout(() => (this.error = null), 5000);
      }

      this.isLoading = false;
    },
    gotoSignUp() {
      if (this.$route.query.redirect) {
        this.$router.push({ name: 'signup', query: { redirectFullPath: this.$route.query.redirect }});
      } else if (this.$route.query.redirectFullPath) {
        this.$router.push({ name: 'signup', query: { redirectFullPath: this.$route.query.redirectFullPath }});
      } else {
        this.$router.push({ name: 'signup' });
      }
    },
    redirect() {
      this.SET_REDIRECT(null);

      if (this.$route.query.redirect) {
        window.location.href = this.$route.query.redirect;
      } else if (this.$route.query.redirectFullPath) {
        window.location.href = this.$route.query.redirectFullPath;
      } else {
        window.location.href = '/';
      }
    },
  },
  mounted() {
    this.loginForm.merchantId = this.profile.merchant_id;
    this.backFullPath = this.$route.query.backFullPath ?? '/';
  },
};
</script>
<template>
  <v-sheet 
    class="login-body-sheet mx-auto pt-6"
    elevation="0"
  >
    <ValidationObserver ref="form">
      <form>
        <v-card color="#f7efe8" elevation="0">
          <v-card-title class="justify-center h4 font-weight-bold pb-2">
            <span style="color:#122944;">Log in</span> <span class="tertiary--text ml-2">as member</span>
          </v-card-title>
          <v-card-text class="text-center pb-3">
            <v-row class="px-3">
              <v-col cols="12" class="px-5 pb-1">
                <ValidationProvider
                  name="Contact No"
                  :rules="{ required: true }"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="loginForm.contactNo"
                    class="login-form p-0 m-0"
                    placeholder="Contact Number"
                    label="Contact No"
                    color="primary"
                    autocomplete="contact-no"
                    height="30"
                    flat
                    rounded
                    solo
                    hide-details
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="px-5 pb-1">
                <ValidationProvider
                  vid="password"
                  name="Password"
                  :rules="{ required: true }"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="loginForm.password"
                    class="login-form p-0 m-0"
                    placeholder="Password"
                    label="Password"
                    color="primary"
                    autocomplete="off"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    :error-messages="errors"
                    :success="valid"
                    height="30"
                    flat
                    rounded
                    solo
                    hide-details
                    @click:append="show = !show"
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row class="px-3 mt-1 mb-0">
              <v-col cols="12">
                <a
                  href="/forgotpassword"
                  class="d-flex justify-content-center text-caption font-weight-bold"
                >
                  Forgot password ?
                </a>
              </v-col>
            </v-row>
            <v-row class="px-3 mt-0">
              <v-col cols="6" class="pl-10">
                <v-btn
                  :disabled="isLoading || client"
                  class="login-btn-back text-caption font-weight-6 white--text text-decoration-none py-4"
                  block
                  :to="{ path: backFullPath }"
                >
                  <v-icon small class="mr-1">mdi-arrow-left</v-icon>Back
                </v-btn>
              </v-col>
              <v-col cols="6" class="pr-10">
                <v-btn
                  :disabled="isLoading || client"
                  color=""
                  class="login-btn-submit text-caption font-weight-6 white--text py-4"
                  block
                  @click="login()"
                >
                  <v-icon small class="mr-1">mdi-login</v-icon>Login
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </form>
      <v-alert
        v-if="error"
        class="alert-global text-body-2 white--text p-2"
        type="error"
        transition="fade-transition"
        dense
        >{{ error }}</v-alert
      >
    </ValidationObserver>
    <v-img
      contain
      class="position-absolute"
      position="bottom right"
      src="@/assets/dayonedayone/images/signup-footer-bg.webp"
      style="bottom:40px; right:16px;"
      width="80%"
    ></v-img>
    <div class="text-center position-relative" @click="gotoSignUp()">
      <span class="text-caption font-weight-6">Not a member?</span>
      <a
        class="text-caption tertiary--text font-weight-bold ml-1"
      >
        Sign Up
      </a>
    </div>
  </v-sheet>
</template>
<style scoped>
.login-body-sheet {
  background-color: #f7efe8;
  height: 100dvh;
}

.login-header-sheet, .login-success-sheet {
  background-color: var(--v-primary);
}

.login-body-sheet /deep/ .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  border: none !important;
  background: #f0d2b7 !important;
  z-index: 1 !important;
}

.login-body-sheet /deep/ .tnc .v-label {
  font-size: 12px !important;
  font-weight: bold;
  color: var(--v-dark);
  margin-top: 7px;
}

.login-body-sheet /deep/ .login-btn-back,
.login-body-sheet /deep/ .login-btn-submit {
  background-color: var(--v-buttonBackground) !important;
  color: #fff !important;
  z-index: 1;
}

.login-form ::placeholder {
  color: var(--v-dark) !important;
  font-size: 12px;
  font-weight: 600;
}

.alert-global {
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(90vw - 48px);
}

@media screen and (min-width: 1024px) {
  .alert-global {
    width: 326px;
  }
}
</style>